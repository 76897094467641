import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { DataTableType, TimeUnit } from '@/components/UI/DataTable/DataTable.model';
import SubHeader from '@/components/UI/SubHeader';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { AnimalApiId } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { Study } from '@/model/Study.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchEntity } from '@/support/Hooks/fetch';
import useStepForm from '@/support/Hooks/form/useStepForm';
import { web as webRoute, web as webRoutes } from '@/support/route';
import type { ReactNode } from 'react';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, UnpackNestedValue, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { handlePayload } from './Show.utils';
import { AnimalSelection } from './Steps/AnimalSelection';
import { Information } from './Steps/Information';

interface CreateDatatableProps {
  study: Study;
}

export const Show = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  if (studyLoading) {
    return <Loading />;
  }

  if (_isNotEmpty(study)) {
    return <CreateDatatable study={study} />;
  } else {
    return <ApiErrorBanner title="Study not found" text="A study is required to create a datatable" />;
  }
};

export interface DataTableFormValues {
  animals: Array<AnimalApiId>;
  name: string;
  unit: string;
  timeStart: number | null;
  timeStartUnit: TimeUnit;
  timeEvery: number | null;
  timeEveryUnit: TimeUnit;
  timeDuration: number | null;
  timeDurationUnit: TimeUnit;
  manualTimepoints: Array<{ timepoint: number | null; unit: TimeUnit }>;
  type: DataTableType;
  timeType: 'automatic' | 'manual';
  animalLoaded: boolean;
}

const stepOrder: Array<ReactNode> = [<Information />, <AnimalSelection />];

export const initialDataTableCreateState: DataTableFormValues = {
  animals: [],
  name: '',
  type: 'custom',
  unit: '',
  timeType: 'automatic',
  timeStart: 0,
  timeStartUnit: TimeUnit.seconds,
  timeEvery: 10,
  timeEveryUnit: TimeUnit.seconds,
  timeDuration: 60,
  timeDurationUnit: TimeUnit.seconds,
  manualTimepoints: [{ timepoint: null, unit: TimeUnit.seconds }],
  animalLoaded: false,
};

const CreateDatatable = ({ study }: CreateDatatableProps) => {
  const { api_id: studyId, id } = study;
  const history = useHistory();
  const [formState, setFormState] = useState<DataTableFormValues>(initialDataTableCreateState);
  const { step, nextStep, backStep } = useStepForm({
    totalSteps: stepOrder.length,
  });
  const formMethods = useForm<DataTableFormValues>({
    defaultValues: formState,
    mode: 'onChange',
    shouldUnregister: false,
  });
  const { handleSubmit, watch } = formMethods;
  const { invoke: postDataTable, loading: dataTablePosting } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/datatables',
    path: { studyApiId: studyId },
    invokeOnInit: false,
  });

  const animals: Array<UnpackNestedValue<ID>> = watch('animals');
  const animalsLoaded: boolean = watch('animalsLoaded');
  const isFinalStep: boolean = step + 1 >= stepOrder.length;

  const handleNext = async (data: DataTableFormValues): Promise<void> => {
    const updatedFormData = { ...formState, ...data };
    if (isFinalStep) {
      const response = await postDataTable({ path: { studyApiId: studyId }, body: handlePayload(updatedFormData) });
      history.push(
        webRoutes('studies.dataTables.show', {
          id: study?.id,
          dataTableApiId: response?.body?.id,
        })
      );
    } else {
      setFormState(updatedFormData);
      nextStep();
    }
  };

  const handleBackstep = () => (step > 0 ? backStep() : history.push(webRoute('studies.dataTables.list', { id })));

  return (
    <>
      <SubHeader linkToText="Data tables" link={webRoute('studies.dataTables.list', { id })} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleNext)} data-test-component="CreateDatatable" data-test-element="form">
          {stepOrder[step]}
          {(!isFinalStep || animalsLoaded) && (
            <div className="ph4 pt3 pb4" data-test-component="CreateDatatable" data-test-element="actions">
              <Button
                submit
                className="mr2"
                testId="next-or-create"
                disabled={dataTablePosting || (isFinalStep && _isEmpty(animals.length))}
              >
                {isFinalStep ? 'Create' : 'Next'}
              </Button>
              <Button plain testId="back" onClick={handleBackstep}>
                Back
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default Show;
