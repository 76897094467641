import { TimezoneSelect } from '@/components/TimezoneSelect/TimezoneSelect.tsx';
import Button from '@/components/UI/Button';
import { components } from '@/generated/internal-api/openapi-types';
import { successToast, warningToast } from '@/helpers.tsx';
import { Study } from '@/model/Study.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { timeZonesSelector } from '@/support/Selectors.tsx';
import { useModalAction } from '@/utils/modal.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
import { isClosed } from '@/support/study.ts';

type UpdateStudyExecutionTimezoneRequest = components['schemas']['UpdateStudyExecutionTimezoneRequest.schema'];

const selector = createSelector([timeZonesSelector], (timezones) => ({ timezones }));
export const StudyExecutionTimezone: FC<{ study: Study; onTimezoneChange: () => void }> = ({
  study,
  onTimezoneChange,
}) => {
  const { timezones } = useSelector(selector);
  const { loading: taskCountByStatusLoading, response: taskCountByStatus } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/tasks/count-by-status',
    path: { studyApiId: study.api_id },
    options: { onError: { slug: 'fetch-completed-study-tasks' } },
  });
  const { invoke: updateTimezone } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyApiId}/timezone',
    path: { studyApiId: study.api_id },
    invokeOnInit: false,
  });
  const { openModal } = useModalAction();

  const formDisabled = useMemo(
    () => !taskCountByStatusLoading && taskCountByStatus?.body?.done !== 0,
    [taskCountByStatusLoading, taskCountByStatus]
  );

  const formMethods = useForm<UpdateStudyExecutionTimezoneRequest>({
    disabled: formDisabled,
    defaultValues: { timezone: study.config.timezone },
  });

  const handleSubmit = async (form: UpdateStudyExecutionTimezoneRequest) =>
    new Promise<boolean>((resolve) => {
      if ((taskCountByStatus?.body?.total ?? 0) > 0) {
        openModal('CONFIRM_STUDY_EXECUTION_TIMEZONE_CHANGE', {
          totalTaskCount: taskCountByStatus?.body?.total,
          onClick: async () => resolve(true),
          onCancel: async () => resolve(false),
        });
      } else {
        resolve(true);
      }
    }).then((update) => {
      if (update) {
        return updateTimezone({ path: { studyApiId: study.api_id }, body: form }).then(() => {
          successToast('Timezone updated');
          formMethods.reset(
            { ...form },
            {
              keepIsSubmitted: false,
              keepSubmitCount: false,
              keepDirty: false,
              keepDirtyValues: false,
              keepDefaultValues: false,
              keepErrors: false,
              keepIsSubmitSuccessful: false,
              keepValues: false,
              keepIsValid: true,
              keepTouched: false,
              keepIsValidating: false,
            }
          );
          onTimezoneChange();
        });
      } else {
        warningToast('Timezone update aborted');
        formMethods.reset();
      }
    });

  return (
    <div className="ui-card mt4">
      <div className="pa4">
        <div className="mb3">
          <h4 className="fw5">Study execution timezone</h4>
          <p>All tasks will be created and updated according to this timezone</p>
        </div>
        <div
          className="mb3"
          data-tooltip-id="global-tooltip-id"
          data-tooltip-place="top-start"
          data-tooltip-content={
            (taskCountByStatus?.body?.done ?? 0) > 0
              ? 'Timezone cannot be updated once tasks have been completed'
              : undefined
          }
        >
          <Controller
            name="timezone"
            control={formMethods.control}
            render={({ field, formState }) => (
              <TimezoneSelect
                value={field.value}
                timezones={timezones}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={field.disabled || formState.isSubmitting || isClosed(study)}
              />
            )}
          />
        </div>

        <Button
          testId="apply-timezone-update"
          disabled={
            formMethods.formState.isSubmitting ||
            !formMethods.formState.isValid ||
            !formMethods.formState.isDirty ||
            isClosed(study)
          }
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
