// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { filterDataByFilters } from '@/components/UI/Table/Table.utils';
import { errorToast, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import useGroupedDraggable from '@/support/Hooks/draggable/useGroupedDraggable';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { sortAscByProperty } from '@/utils/array';
import { modalAction } from '@/utils/modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AssignCages from './AssignCages';
import CageColumns from './CageColumns';
import CageFilter from './CageFilter';
import { assembleCageFilterOptions } from './CageFilters';
import { findDuplicateNames } from '@/components/Cages/Show/Show.utils.ts';
import { cageNameValidater } from '@/components/Colony/Colony.utils.ts';
import { Cage } from '@/model/Cage.model.ts';
import { Animal } from '@/model/Animal.model.ts';

export interface CageCardCollection extends Cage {
  subjects?: Array<Animal>;
  isVisible?: boolean;
}

const Cages = ({ cages = [], study, fetchCages, inStudyView }) => {
  const [showDuplicatesOnly, setShowDuplicatesOnly] = useState(false);
  // const [duplicateCageApiIds] = useState(new Set());
  const [deleteError, setDeleteError] = useState(false);
  const [collections, setCollections] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterMatch, setFilterMatch] = useState(true);
  const [filterOptions, setFilterOptions] = useState([]);
  const [animalIdProperty, setAnimalIdProperty] = useState('name');
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const {
    collection: animals,
    collectionLoading: animalsLoading,
    collectionError: animalError,
    fetchCollection: fetchAnimals,
  } = useFetchCollection({
    queryParams: {
      ...(_isNotEmpty(cages)
        ? {
            filterType: 'and',
            'filters[]=': `cage|in|${cages.map(({ id }) => id).join(',')}`,
          }
        : {}),
      perPage: '-1',
    },
    collectionType: inStudyView ? 'studyAnimals' : 'animals',
    params: { ...(inStudyView ? { id: study?.api_id } : {}) },
  });

  const {
    collection: cageMetadata,
    collectionLoading: cageMetadataLoading,
    collectionError: cageError,
  } = useFetchCollection({
    queryParams: {
      filter_type: 'collection_meta',
    },
    collectionType: 'metadataGlossary',
  });

  const initializeState = () => {
    const oldCages = [...collections];
    const initialCollections = findDuplicateNames(
      cages.map((collection) => {
        const visible = oldCages.find((cage) => cage.id === collection.id)?.isVisible;
        const subjects = animals.filter((animal) => animal.cage_id === collection.id);
        return {
          ...collection,
          isVisible: visible ?? true,
          subjects: {
            data: subjects.sort(sortAscByProperty('number')),
          },
        };
      })
    );
    setCollections(initialCollections);
    const filterOptions = assembleCageFilterOptions(study?.study_groups || []);
    setFilterOptions(filterOptions);
  };

  useEffect(() => {
    if (animals) {
      initializeState();
    }
  }, [animals]);

  useEffect(() => {
    fetchAnimals();
  }, [cages]);

  const updateFilters = (filters, filterMatch) => {
    setFilters(filters);
    setFilterMatch(filterMatch);
  };

  const addFilter = (filter) => {
    const updatedFilters = [...filters];
    if (_isEmpty(updatedFilters.find((f) => filter.value === f.value))) {
      updatedFilters.push(filter);
      setFilters(updatedFilters);
    }
  };

  const validateCageName = (name) => {
    return !collections.some((c) => c.name === name) && cageNameValidater(name);
  };

  const updateCageAPI = (cage) => {
    Http.put(apiRoute('cages.update', { id: cage.id }), {
      name: cage.name,
    }).catch(() => {
      errorToast('There was a problem renaming the cage');
    });
  };

  const handleCageNameChange = (cage) => {
    setCollections((prevCages) => {
      const updatedCages = prevCages.map((prevCage) =>
        prevCage.id === cage.id
          ? {
              ...prevCage,
              name: cage.name,
            }
          : prevCage
      );
      const duplicatedCageNames = findDuplicateNames(updatedCages);
      if (showDuplicatesOnly && duplicatedCageNames.every((cage) => !cage.duplicateCageName)) {
        setShowDuplicatesOnly(false);
      }
      return duplicatedCageNames;
    });
  };

  const updateCageState = (cage) => {
    const updatedCages = [...collections];
    const index = updatedCages.findIndex((c) => c.id === cage.id);
    updatedCages[index] = cage;
    setCollections(updatedCages);
  };

  const removeCageFromState = (id) => {
    const updatedCages = [...collections];
    const index = updatedCages.findIndex((c) => c.id === id);
    updatedCages.splice(index, 1);
    setCollections(updatedCages);
  };

  const deleteCage = async (collection) => {
    try {
      await Http.delete(apiRoute('cages.destroy', { id: collection.id }));
      removeCageFromState(collection.id);
      successToast('Successfully deleted cage');
    } catch (error) {
      setDeleteError(error);
    }
  };

  const toggleDeleteCageModal = (collection) => {
    openModal('CONFIRM_DELETE_COLLECTION', {
      closeModal,
      onClick: () => deleteCage(collection),
    });
  };

  const handleAddAnimalsCallback = (response, cage) => {
    const {
      data: { data: animalsCreated },
    } = response;
    const updatedAnimalsInCage = {
      ...cage,
      subjects: { data: [...cage.subjects.data, ...animalsCreated] },
    };
    updateCageState(updatedAnimalsInCage);
    closeModal();
  };

  const toggleAddAnimalsModal = (cage) => {
    openModal('ADD_SUBJECTS', {
      cage,
      handleCallback: handleAddAnimalsCallback,
    });
  };

  const filterSubjects = useCallback(
    (subjects, filters, filterMatch, filterOptions) => {
      return filterDataByFilters(subjects, filters, filterMatch, filterOptions);
    },
    [collections, filters, filterMatch, filterOptions]
  );

  const cagesWithFilteredSubjects = useMemo(() => {
    return collections
      .reduce((acc, cage) => {
        if (!cage.isVisible) {
          return acc;
        }

        const filteredSubjects = filterSubjects(cage.subjects.data, filters, filterMatch, filterOptions);
        if (_isEmpty(filteredSubjects) && _isNotEmpty(filters)) {
          return acc;
        }

        const updatedCage = {
          ...cage,
          subjects: {
            ...cage.subjects,
            data: filteredSubjects,
          },
        };

        if (showDuplicatesOnly && !updatedCage.duplicateCageName) {
          return acc;
        }
        acc.push(updatedCage);
        return acc;
      }, [])
      .sort(sortAscByProperty('id'));
  }, [collections, filters, filterMatch, filterOptions, showDuplicatesOnly]);

  const { addItemCallback, moveCallback } = useGroupedDraggable(cagesWithFilteredSubjects, setCollections);

  const pageLoading = cageMetadataLoading || animalsLoading;
  if (pageLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  const cagesEmpty = _isEmpty(collections);
  const error = cageError || animalError;

  return error ? (
    <ApiErrorBanner className="ml3" errorType={ApiErrorType.FETCH} error={error} />
  ) : (
    <div className={`${!cagesEmpty ? 'bg-white' : ''} h-100 overflow-auto`}>
      {deleteError && <ApiErrorBanner className="ml3" errorType={ApiErrorType.FETCH} error={deleteError} />}
      <div className="pb5">
        {cagesEmpty ? (
          <AssignCages study={study} setCages={setCollections} fetchCages={fetchCages} />
        ) : (
          <>
            <CageFilter
              study={study}
              cages={collections}
              fetchCages={fetchCages}
              setCages={setCollections}
              filters={filters}
              filterMatch={filterMatch}
              filterOptions={filterOptions}
              updateFilters={updateFilters}
              addFilter={addFilter}
              animalIdProperty={animalIdProperty}
              setAnimalIdProperty={setAnimalIdProperty}
              inStudyView={inStudyView}
              metadata={cageMetadata ? cageMetadata : []}
              showDuplicatesOnly={showDuplicatesOnly}
              setShowDuplicatesOnly={setShowDuplicatesOnly}
            />
            <CageColumns
              study={study}
              cages={cagesWithFilteredSubjects}
              toggleAddAnimalsModal={toggleAddAnimalsModal}
              toggleDeleteCageModal={toggleDeleteCageModal}
              onMove={moveCallback}
              onAddMove={addItemCallback}
              updateCageAPI={updateCageAPI}
              validateCageName={validateCageName}
              onCageNameChange={handleCageNameChange}
              animalIdProperty={animalIdProperty}
              inStudyView={inStudyView}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Cages;
