import useStepForm from '@/support/Hooks/form/useStepForm';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form@latest';

import { SelectAnimals } from '@/components/Studies/Templates/CreateDataTable/steps/SelectAnimals.tsx';
import { SelectTemplate } from '@/components/Studies/Templates/CreateDataTable/steps/SelectTemplate.tsx';
import {
  CreateDataTableFromTemplateFormState,
  DataTableCreateFromTemplateStepRenderProps,
  OptionalCreateDataTableFromTemplateFormState,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.utils.tsx';
import { useParams } from 'react-router-dom';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import type { Study } from '@/model/Study.model.ts';
import { _isNil } from '@/littledash.ts';
import Loading from '@/components/Loading';

const steps = [
  {
    name: 'select_template',
    title: 'Select Template',
    render: (props: DataTableCreateFromTemplateStepRenderProps) => <SelectTemplate {...props} />,
  },
  {
    name: 'select_animals',
    title: 'Select Animals',
    render: (props: DataTableCreateFromTemplateStepRenderProps) => <SelectAnimals {...props} />,
  },
];

const CreateDataTableFromTemplate: FC = () => {
  const { id: studyId } = useParams<{ id: string }>();

  const { response: studyResponse, loading: studyLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.show.p',
    path: { id: studyId },
    query: { include: 'users' },
    options: { onError: { toast: true, slug: 'study-load' } },
  });

  const study = (studyResponse?.body as { data: Study })?.data;
  // @ts-expect-error - Partial type
  const initialValues: CreateDataTableFromTemplateFormState = useMemo(
    () => ({ data_table_name: '', template: {}, columns: [], animals: [] }),
    []
  );
  const [stepFormState, setStepFormState] = useState<CreateDataTableFromTemplateFormState>(initialValues);
  const formMethods = useForm<CreateDataTableFromTemplateFormState>({ defaultValues: initialValues, mode: 'onChange' });
  const { reset: resetForm } = formMethods;

  const { step: currentStep, nextStep, backStep, hasBack, hasNext } = useStepForm({ totalSteps: steps.length });
  const handleNextStep = (stepFormData: OptionalCreateDataTableFromTemplateFormState): void => {
    const updatedStepFormPayload: CreateDataTableFromTemplateFormState = { ...stepFormState, ...stepFormData };
    setStepFormState(updatedStepFormPayload);
    if (hasNext) {
      // On each step reset the defaultValues cache with the updated form state
      resetForm(updatedStepFormPayload);
      nextStep();
    } else {
      postDataTableFromTemplate(updatedStepFormPayload);
    }
  };

  const postDataTableFromTemplate = (updatedStepFormPayload: CreateDataTableFromTemplateFormState) => {};

  const handleBackStep = () => {
    if (hasBack) {
      backStep();
    }
  };

  if (studyLoading || _isNil(study)) {
    return <Loading txt="Loading Data table form" />;
  }

  return (
    <FormProvider {...formMethods}>
      <div>
        {steps?.[currentStep]?.render({
          onSubmit: handleNextStep,
          onCancel: handleBackStep,
          submitButtonText: hasNext ? 'Next' : 'Submit',
          cancelButtonText: hasBack ? 'Back' : 'Cancel',
          study,
        }) ?? null}
      </div>
    </FormProvider>
  );
};

export default CreateDataTableFromTemplate;
