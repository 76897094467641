import AnimalsTable from '@/components/Animals/AnimalsTable/AnimalsTable';
import Header from '@/components/UI/Header';
import type { ID } from '@/model/Common.model';
import { FetchProvider } from '@/support/Hooks/fetch';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form@latest';
import {
  CreateDataTableFromTemplateFormState,
  DataTableCreateFromTemplateStepRenderProps,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.utils.tsx';
import Button from '@/components/UI/Button';
import { AnimalApiId } from '@/model/Animal.model.ts';
import { _isNotEmpty } from '@/littledash.ts';

interface SelectedRowProp {
  original: { api_id: AnimalApiId };
}

export const SelectAnimals: React.FC<DataTableCreateFromTemplateStepRenderProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  study,
}) => {
  const {
    handleSubmit: useFormHandleSubmit,
    formState,
    watch,
    setValue,
  } = useFormContext<CreateDataTableFromTemplateFormState>();

  const animals = watch('animals');

  useEffect(() => {
    if (_isNotEmpty(animals)) {
      setValue('animals', []);
    }
  }, []);

  const handleSelection = (selectedFlatRows: Array<ID>) =>
    // @ts-expect-error - Type error for mapping flatrows
    setValue('animals', [...(selectedFlatRows ?? []).map((row: SelectedRowProp) => row.original.api_id)]);

  return (
    <>
      <form onSubmit={useFormHandleSubmit(onSubmit)} data-test-component="SelectAnimals" data-test-element="form">
        <div className="ph4 pv3">
          <Header mainHeaderText="Choose animals" />
        </div>
        <div className="ph4" data-test-component="SelectAnimals" data-test-element="api-table-container">
          {/* @ts-expect-error - Type error for entity in FetchProvider */}
          <FetchProvider entity={study}>
            {/* @ts-expect-error - Type error for enableBulkActions */}
            <AnimalsTable enableBulkActions={false} onSelectRow={handleSelection} tableMaxHeight={550} />
          </FetchProvider>
        </div>
        <div className="pa3 b--moon-gray">
          <Button
            submit
            testKey="createDataTableFromTemplateStepFormSubmit"
            testId="select-animals-submit"
            disabled={!formState.isValid || animals.length < 1}
          >
            {submitButtonText}
          </Button>
          <Button plain className="ml2" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        </div>
      </form>
    </>
  );
};
