// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Filters from '@/components/UI/Filters';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import CagesShowMenu from '@/components/UI/SelectDropDown/Menus/CagesShowMenu';
import TableFilterMenu from '@/components/UI/SelectDropDown/Menus/TableFilterMenu';
import StickyItem from '@/components/UI/Sticky';
import * as Auth from '@/support/auth';
import { web as webRoute } from '@/support/route';
import { modalAction } from '@/utils/modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { countUniqueDuplicates } from '@/components/Cages/Show/Show.utils.ts';
import Icon from '@/components/UI/Icon';

const ID_OPTIONS = [
  {
    name: 'Name',
    accessor: 'name',
  },
  {
    name: 'Tag',
    accessor: 'alt_ids.tag',
  },
  {
    name: 'Tail',
    accessor: 'alt_ids.tail',
  },
  {
    name: 'Ear',
    accessor: 'alt_ids.ear',
  },
  {
    name: 'Donor',
    accessor: 'alt_ids.donor',
  },
];

const FilterCagesLabel = ({ showDuplicatesOnly, uniqueDuplicateCount, onClick }) => (
  <div className="flex items-center mr3">
    <Button
      soft
      onClick={onClick}
      style={{
        backgroundColor: showDuplicatesOnly && 'gray',
        color: showDuplicatesOnly && 'white',
      }}
    >
      <div className="flex items-center">
        <span className="flex mt2 mr2">
          <Icon icon="critical_alert" className="dark-red" width="22" height="22" />
        </span>
        <p className="f6">{`${uniqueDuplicateCount} duplicate${uniqueDuplicateCount > 1 ? 's' : ''} exist${uniqueDuplicateCount === 1 ? 's' : ''}`}</p>
      </div>
    </Button>
  </div>
);

const CageFilter = ({
  study,
  cages,
  fetchCages,
  setCages,
  filters,
  filterMatch,
  updateFilters,
  filterOptions,
  animalIdProperty,
  setAnimalIdProperty,
  inStudyView,
  showDuplicatesOnly,
  setShowDuplicatesOnly,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const { features } = useSelector(({ team: { features } }) => ({
    features,
  }));

  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const onComplete = () => {
    fetchCages();
  };
  const openAtunesImportModal = () => {
    openModal('ATUNES_IMPORT_ANIMALS', { studyApiId: study.api_id, onComplete });
  };
  const openCreateCageModal = () => {
    openModal('ADD_CAGES', {
      study,
      handleCallback: (res) => {
        const {
          data: { data: newCages },
        } = res;
        const newCagesWithEmptySubjects = newCages.map((cage) => ({
          ...cage,
          isVisible: true,
          subjects: { data: [] },
        }));
        const updateCages = [...cages, ...newCagesWithEmptySubjects];
        setCages(updateCages);
        closeModal();
      },
    });
  };

  const selectedAnimalId = ID_OPTIONS.find((id) => id.accessor === animalIdProperty);

  const createWithColony = () => {
    history.push(
      webRoute('colony.add.study', {
        studyId: study.id,
      })
    );
  };

  const assignFromColony = () => {
    history.push(
      webRoute('colony.add.cage', {
        studyId: study.id,
      })
    );
  };

  const actions = [
    ...(atunesIntegrationEnabled
      ? []
      : [
          {
            name: 'Quick create cages',
            action: () => openCreateCageModal(),
          },
        ]),
    {
      name: 'Assign cages from Colony',
      action: assignFromColony,
    },
  ];

  if (!atunesIntegrationEnabled) {
    actions.push({
      name: 'Create cages with animals',
      action: createWithColony,
    });
  }

  const uniqueDuplicateCount = countUniqueDuplicates(cages);

  return (
    <React.Fragment>
      <StickyItem>
        <div className={'bg-white bb b--moon-gray flex justify-between flex-wrap-l ph4 pv3'}>
          <div>
            <div className="dib">
              <SelectDropDown className="dib plain f6" title="Show" alignMenu="left" testId="cage-filter">
                <CagesShowMenu items={cages} handleChange={setCages} />
              </SelectDropDown>
            </div>
            <div className="dib ml3">
              <SelectDropDown className="dib plain f6" title="Filter" alignMenu="left">
                <TableFilterMenu
                  filterOptions={filterOptions}
                  filters={filters}
                  onUpdateFilters={updateFilters}
                  filterMatch={filterMatch}
                />
              </SelectDropDown>
            </div>
            <div className="dib ml3">
              <SelectDropDown className="dib plain f6" title={`ID: ${selectedAnimalId.name}`} alignMenu="left">
                <ActionList
                  actions={ID_OPTIONS.map((o) => ({
                    name: o.name,
                    action: () => setAnimalIdProperty(o.accessor),
                  }))}
                />
              </SelectDropDown>
            </div>
            {study && <div className="f6 near-black lh-title dib ml3">{study.name}</div>}
          </div>
          <div className="flex align-center">
            {atunesIntegrationEnabled && (
              <Button onClick={openAtunesImportModal} className="mr3" plain>
                Import from tick@lab
              </Button>
            )}
            <div>
              {inStudyView ? (
                <div className="flex w-100">
                  {cages.some((cage) => cage.duplicateCageName) ? (
                    <FilterCagesLabel
                      uniqueDuplicateCount={uniqueDuplicateCount}
                      showDuplicatesOnly={showDuplicatesOnly}
                      onClick={() => setShowDuplicatesOnly((prev) => !prev)}
                    />
                  ) : (
                    ''
                  )}
                  <SelectDropDown
                    disabled={!Auth.isWriteUserForStudy(study)}
                    className="dib primary f6"
                    title="Add"
                    alignMenu="right"
                  >
                    <ActionList actions={actions} />
                  </SelectDropDown>
                </div>
              ) : (
                <Button onClick={openCreateCageModal}>Create cages</Button>
              )}
            </div>
          </div>
        </div>
      </StickyItem>
      <div className="ph3 ph4-l mb3">
        <Filters filters={filters} updateFilters={updateFilters} filterMatch={filterMatch} />
      </div>
    </React.Fragment>
  );
};

export default CageFilter;
