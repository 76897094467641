import {
  CreateDataTableFromTemplateFormState,
  DataTableCreateFromTemplateStepRenderProps,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.utils.tsx';
import { useFormContext, Controller } from 'react-hook-form@latest';
import Button from '@/components/UI/Button';
import { FormField } from '@/components/UI/FormFields/Latest';
import { TemplateLookup } from '@/components/Studies/Templates/CreateDataTable/TemplateLookup.tsx';
import Header from '@/components/UI/Header';
import React from 'react';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils.ts';
import { _isNil } from '@/littledash.ts';

export const SelectTemplate: React.FC<DataTableCreateFromTemplateStepRenderProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  study,
}) => {
  const {
    handleSubmit: useFormHandleSubmit,
    control,
    formState,
    watch,
    register,
  } = useFormContext<CreateDataTableFromTemplateFormState>();

  const template = watch('template');

  return (
    <>
      <div className="ph4 pv3">
        <div>
          <Header mainHeaderText="Create using template" />
        </div>
        <form onSubmit={useFormHandleSubmit(onSubmit)} data-test-component="SelectTemplate" data-test-element="form">
          <div className="pt4">
            <div
              className="w-30"
              data-test-component="SelectTemplate"
              data-test-element="select-template-title-container"
            >
              <FormField name="data_table_name" label="Name">
                <input
                  type="text"
                  data-test-component="Text"
                  data-test-element="input"
                  style={{ marginBottom: 0 }}
                  className={formState.errors?.['data_table_name'] ? 'input__error' : ''}
                  {...register('data_table_name', getRegisterConfig({ required: true, maxLength: 250 }))}
                  placeholder={'Data Table Name'}
                  data-testid={'data-table-name-input'}
                />
              </FormField>
            </div>
            <div className="w-30 pt3" data-test-component="Select-Template" data-test-element="select-template-lookup">
              <FormField name="template" label="Template">
                <Controller
                  name="template"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TemplateLookup value={value} onChange={onChange} onBlur={onBlur} study={study} />
                  )}
                />
              </FormField>
            </div>
          </div>
          <div className="pt5 b--moon-gray">
            <Button
              submit
              testKey="createDataTableFromTemplateStepFormSubmit"
              testId="select-template-submit"
              disabled={!formState.isValid || _isNil(template?.api_id)}
            >
              {submitButtonText}
            </Button>
            <Button plain className="ml2" onClick={onCancel} testId="select-template-cancel">
              {cancelButtonText}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
