import Banner from '@/components/UI/Banner';
import Link from '@/components/UI/Link';
import { FC, MouseEventHandler, ReactNode } from 'react';

interface NoDataCardProps {
  title: string;
  text?: string;
  btnTxt?: string | boolean;
  dark?: boolean;
  className?: string;
  onLinkClick?: MouseEventHandler;
  openModal?: boolean;
  isFullScreen?: boolean;
  link?: string;
  NoDataComponent?: ReactNode;
  btnInline?: boolean;
  fluidHeight?: boolean;
  disabled?: boolean;
}

const NoDataCard: FC<NoDataCardProps> = (props) =>
  props.isFullScreen ? (
    <PageWrapper>
      <Card className={props.className}>
        <NoDataMessage {...props} />
      </Card>
    </PageWrapper>
  ) : (
    <Card className={props.className}>
      <NoDataMessage {...props} />
    </Card>
  );

interface NoDataMessageProps {
  title: string;
  text?: string;
  onLinkClick?: MouseEventHandler;
  link?: string;
  btnTxt?: string | boolean;
  btnInline?: boolean;
  bannerTxt?: string;
  NoDataComponent?: ReactNode;
  dark?: boolean;
  openModal?: boolean;
  disabled?: boolean;
  fluidHeight?: boolean;
}

export const NoDataMessage: FC<NoDataMessageProps> = ({
  title,
  text,
  bannerTxt,
  onLinkClick,
  link,
  btnTxt,
  btnInline = false,
  NoDataComponent,
  dark,
  openModal,
  disabled,
  fluidHeight = false,
}) => (
  <div
    className={`pa4 flex flex-column justify-center items-center ${dark ? 'bg-near-white br2' : 'ui-card'}`}
    style={{ minHeight: fluidHeight ? 'auto' : 300 }}
    data-test-component="NoDataMessage"
    data-test-element="container"
  >
    <div className="mw6 flex flex-column justify-center items-center">
      <h3 className="ma0 f5 normal lh-copy">{title}</h3>
      {text && (
        <p className="ma0 pt3 f5 lh-copy tc" style={{ marginBottom: 0 }} data-test-element="link-container">
          {text}
          {link && btnInline && (
            <Link to={link} className={'mt3 link'}>
              {` ${btnTxt}`}
            </Link>
          )}
        </p>
      )}
      {bannerTxt && (
        <Banner info dismiss={false}>
          <p className="lh-copy f6 near-black mt3">{bannerTxt}</p>
        </Banner>
      )}
      <>{NoDataComponent}</>
      {onLinkClick && !btnInline && (
        <Link onClick={onLinkClick} disabled={disabled} openModal={openModal} className={'mt3 link'}>
          {btnTxt}
        </Link>
      )}
      {link && !btnInline && (
        <Link to={link} className={'mt3 link'}>
          {btnTxt}
        </Link>
      )}
    </div>
  </div>
);

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({ children }) => (
  <div className="w-100 h-100 flex items-center justify-center-l">{children}</div>
);

interface CardProps {
  children: ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({ children, className }) => <div className={`${className ?? ''}`}>{children}</div>;

export default NoDataCard;
