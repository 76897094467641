import { Dispatch, FC, SetStateAction, useState } from 'react';
import Button from '@/components/UI/Button';
import type { RepeatValue, TaskSpec } from '@/model/Task.model';
import ApiErrorBanner from '@/components/ApiErrorBanner';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import InputWithUnits from '@/components/UI/FormElements/InputWithUnits';
import { _isNil, _notNil } from '@/littledash';
import { repeatPeriodOptions } from '../AddTask/Steps/AddTaskScheduleForm/AddTaskScheduleForm.utils';
import { errorToast, successToast } from '@/helpers';
import type { StudyApiId } from '@/model/Study.model';
import { ErrorMessage } from '@hookform/error-message';
import { ApiService } from '@/support/ApiService';
import useMountedState from '@/support/Hooks/fetch/useMountedState';

interface ShiftTaskDatesProps {
  taskSpecs: Array<TaskSpec>;
  studyId: StudyApiId;
  closeModal: () => void;
  refetchTableData: Dispatch<SetStateAction<number>>;
}

interface ShiftTaskDatesFormData {
  dateShift: { unit: 'days' | 'weeks'; value: number };
}

export const positiveValueUnitValidation = (value: RepeatValue) => {
  if (_isNil(value?.value) || _isNil(value?.unit)) {
    return 'Missing required fields';
  } else if (value?.value <= 0) {
    return 'Value must be a positive number';
  } else if (value?.value % 1 != 0) {
    return 'Value must be a whole number';
  }
  return true;
};

export const ShiftTaskDates: FC<ShiftTaskDatesProps> = ({ taskSpecs, studyId, closeModal, refetchTableData }) => {
  const [apiError, setApiError] = useState<boolean | Error>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isMounted = useMountedState();

  const {
    handleSubmit,
    control,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dateShift: { unit: 'days', value: 1 },
    },
  });

  const submitShiftTaskDates = async (form: ShiftTaskDatesFormData) => {
    try {
      setLoading(true);
      await ApiService.call({
        endpoint: 'PATCH /api/v1/studies/{studyId}/task-specs',
        body: { task_spec_ids: taskSpecs.map(({ id }) => id), date_shift: form.dateShift },
        path: { studyId },
      });
      successToast('Successfully updated tasks');
      if (isMounted()) {
        refetchTableData(new Date().getTime());
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      if (isMounted()) {
        setApiError(error as Error);
        errorToast('An error occurred updating tasks, please try again');
        setLoading(false);
      }
    }
  };

  const taskTitles = taskSpecs.map((spec) => spec.title ?? '').join(', ');

  return (
    <form onSubmit={handleSubmit(submitShiftTaskDates)} className="ui__narrow-modal">
      <div className="mb3 tc">
        <h3 className="normal f4 pb2">{`Shift dates for Task${taskSpecs.length > 1 ? 's' : ''}`}</h3>
      </div>
      {apiError && <ApiErrorBanner error={apiError} />}
      <div className="mv3">
        <p className="f6 near-black">
          This will shift the start and end dates for all tasks associated with{' '}
          <span className="fw6">{taskTitles}</span>
        </p>
      </div>
      <div className="pa2 mv3">
        <label>
          <label>Time shift</label>
        </label>
        <Controller
          name="dateShift"
          control={control}
          rules={{
            required: true,
            validate: positiveValueUnitValidation,
          }}
          render={({ value, onChange, onBlur }: ControllerRenderProps) => (
            <InputWithUnits
              name="dateShift"
              value={value.value}
              unit={value.unit}
              unitOptions={Object.values(repeatPeriodOptions)}
              onChange={onChange}
              onBlur={onBlur}
              disabled={loading}
              hasError={_notNil(errors.dateShift)}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="dateShift"
          render={({ message }) => (
            <p className="f6 red db pt2" data-testid="shift-value-error-message">
              {message}
            </p>
          )}
        />
      </div>
      <div>
        <Button submit disabled={!isValid || loading} className="w-100 mb2" testKey="shift-task-save-button">
          Save
        </Button>
        <Button plain className="w-100" onClick={() => closeModal()} testKey="shift-task-cancel-button">
          Cancel
        </Button>
      </div>
    </form>
  );
};
