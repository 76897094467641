import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import ReviewTable from '@/components/Studies/Create/Steps/Groups/ReviewTable';
import Button from '@/components/UI/Button';
import { uuid } from '@/littledash';
import type { DisplayedGroupMetadata } from '@/model/Metadata.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type React from 'react';
import { useDispatch } from 'react-redux';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { modalAction } from '@/utils/modal';
import { RiAlertFill } from 'react-icons/ri';

interface UpdatedTreatmentGroup extends Partial<TreatmentGroup> {
  tempId?: string;
}

interface StudyGroupSetupProps {
  groups: Array<TreatmentGroup>;
  onUpdateGroupData: (groups: Array<UpdatedTreatmentGroup>) => void;
  canEdit?: boolean;
  plainLoading?: boolean;
  addTempId?: boolean;
  conflicts?: number;
}

const StudyGroupSetup: React.FC<StudyGroupSetupProps> = ({
  groups,
  onUpdateGroupData,
  canEdit = true,
  plainLoading = false,
  addTempId = false,
  conflicts = 0,
}) => {
  const {
    collection: savedGroupData,
    collectionLoading: savedGroupDataLoading,
    collectionError: savedGroupDataError,
  } = useFetchCollection<DisplayedGroupMetadata>({
    collectionType: 'teamStudiesGroups',
  });

  const { openModal } = modalAction(useDispatch());
  const onAddGroups = (data: Array<any>) => {
    const updatedStudyGroups = data.map((rowData, rowIndex) => {
      const updatedGroup: UpdatedTreatmentGroup = {
        id: rowData?.id ?? '',
        name: rowData?.name,
        no: rowIndex + 1,
        max_subjects: rowData?.max_subjects,
        color: rowData?.color,
        metadata: rowData?.metadata ?? [],
        created_at: rowData?.created_at,
        updated_at: rowData?.updated_at,
      };
      if (addTempId) {
        updatedGroup['temp_id'] = rowData?.temp_id ?? uuid();
      }
      return updatedGroup;
    });
    onUpdateGroupData(updatedStudyGroups);
  };

  const LoadingComponent = plainLoading ? (
    <Loading txt="Loading treatment groups..." />
  ) : (
    <div
      className="flex flex-wrap w-90 overflow-hidden center ma3 bg-light-gray br2 shadow-4 mt4 mb-2 relative"
      style={{ height: 650 }}
    >
      <Loading txt="Loading treatment groups..." />
    </div>
  );

  return savedGroupDataLoading ? (
    LoadingComponent
  ) : (
    <div data-testid="study-groups-container" className="w-100" data-test-component="StudyGroupSetup">
      {savedGroupDataError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an problem fetching treatment group metadata'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={savedGroupDataError}
        />
      )}
      {groups.length > 0 ? (
        <div className="w-100 hide-child">
          {canEdit && (
            <div className="pv3 ph4 flex justify-between items-center bb b--moon-gray">
              <p className="lh-copy w-100 f6">
                <span>Hover over the table and click 'edit' to amend or add new groups. </span>
                <a
                  href="https://help.benchling.com/hc/en-us/articles/21952642953101"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dib link blue"
                >
                  Read more.
                </a>
              </p>
              {conflicts > 0 && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RiAlertFill className="red" size={25} />
                  <span className="pl2">Amend {conflicts} groups to proceed</span>
                </div>
              )}
              <Button
                plain
                className="child"
                onClick={() =>
                  openModal('CREATE_TREATMENT_GROUPS', {
                    groups,
                    onAddGroups,
                    savedGroupData,
                  })
                }
                testId="group-edit-button"
              >
                Edit
              </Button>
            </div>
          )}
          <ReviewTable groups={groups} groupMetadata={savedGroupData} />
        </div>
      ) : (
        <div
          className="br2 bg-near-white tc flex items-center justify-center flex-column pa4"
          data-test-component="StudyGroupSetup"
          data-test-element="empty-state"
        >
          <div className="mw6 center ph4 pa4">
            <h3 className="mb2">Adding treatment groups</h3>
            <p className="mb3">A preview of your treatment groups and any associated meta-data will appear here</p>
            {canEdit && (
              <Button
                stateless
                className="basier-reg blue ph0 mt2"
                testId="add-treatment-groups-button"
                onClick={() =>
                  openModal('CREATE_TREATMENT_GROUPS', {
                    groups,
                    onAddGroups,
                  })
                }
              >
                Add treatment groups
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default StudyGroupSetup;
