import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import type { DraftFormData } from '@/model/Draft.model';
import type React from 'react';
import { useEffect } from 'react';
import { GroupAndTreatmentConfiguration } from './GroupAndTreatmentConfiguration';
import { GroupCreation } from './GroupCreation';
import { TreatmentCreation } from './TreatmentCreation';

interface GroupsAndTreatmentsCreationProps {
  state: { study: DraftFormData };
  dispatch: React.Dispatch<unknown>;
}

export const GroupsAndTreatmentsCreation: React.FC<GroupsAndTreatmentsCreationProps> = ({
  state: { study },
  dispatch,
}) => {
  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <div data-testid="groups-and-treatments__container">
      <Tabs>
        <div className="bb b--moon-gray">
          <Tab className="f5 f4-l">Groups</Tab>
          <Tab className="f5 f4-l">Treatments</Tab>
          <Tab className="f5 f4-l">Configuration</Tab>
        </div>
        <Panel>
          <GroupCreation study={study} dispatch={dispatch} />
        </Panel>
        <Panel>
          <TreatmentCreation study={study} dispatch={dispatch} />
        </Panel>
        <Panel>
          <GroupAndTreatmentConfiguration study={study} dispatch={dispatch} />
        </Panel>
      </Tabs>
    </div>
  );
};
