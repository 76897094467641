// @ts-nocheck: converted from JS

import {
  tempEntityArrayToMap,
  validateGroups,
} from '@/components/Studies/Create/Steps/GroupsAndTreatments/GroupsAndTreatment.util';
import { _isEmpty } from '@/littledash';
import React, { useEffect, useMemo, useState } from 'react';
import StudyGroupSetup from '../../../Settings/Steps/Groups/StudyGroupSetup';
import { GroupConfiguration } from './GroupConfiguration';
import { MetadataFieldTypes } from '@/model/Metadata.model.ts';
import { TreatmentGroup } from '@/model/TreatmentGroup.model.ts';
import Loading from '@/components/Loading';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';

/**
 *
 * @param props {groups: Array<Group>, treatments: Array<Treatment>, groupTreatments: Array<GroupTreatment>}
 * @returns {Array}
 * @constructor
 */
export const groupTreatmentEntityMap = (groups = [], treatments = [], groups_treatments = []) => {
  const treatmentsMap = tempEntityArrayToMap(treatments);
  const groupTreatmentsMap = new Map(
    groups_treatments.map(({ group, treatments }) => [
      group,
      treatments.map((treatmentId) => treatmentsMap.get(treatmentId)),
    ])
  );
  return groups.map((group) => ({
    ...group,
    treatments: groupTreatmentsMap.get(group.temp_id) ?? [],
  }));
};

/**
 *
 * @param props {{study: {groups: Array<Group>, dispatch: React.Dispatch}}}
 * @returns {JSX.Element}
 * @constructor
 */

export const GroupCreation = ({ study, dispatch }) => {
  const groups = useMemo(
    () => groupTreatmentEntityMap(study?.groups, study?.treatments, study?.groups_treatments),
    [study]
  );
  const [issues, setIssues] = useState([]);

  const handleGroupConfigurationChange = (changedConfiguration) => {
    dispatch({
      type: 'updateStudy',
      data: { ...changedConfiguration },
    });
  };
  const handleGroupChange = (groups) => {
    const updatedGroups = tempEntityArrayToMap(groups);
    const updatedGroupTempIdList = Array.from(updatedGroups.keys());
    const updatedGroupTreatments = study?.groups_treatments ?? [];

    const filteredGroupsTreatments = updatedGroupTreatments.filter((groupTreatment) => {
      const groupKey = groupTreatment.group;
      return updatedGroupTempIdList.includes(groupKey);
    });

    dispatch({
      type: 'updateStudy',
      data: {
        groups: [...updatedGroups.values()],
        groups_treatments: filteredGroupsTreatments,
      },
    });

    updateGroupValidation(groups, requiredMetadata);
  };

  const updateGroupValidation = (groups: Array<TreatmentGroup>, requiredMetadata: Array<MetadataFieldTypes>) => {
    const groupMetadataIssues = validateGroups(groups, requiredMetadata);
    setIssues(groupMetadataIssues);
    const validMetadata = (groupMetadataIssues?.size ?? 0) === 0;
    dispatch({ type: 'stepReady', data: validMetadata });
  };

  const { response: studyGroupsResponse, loading: studyGroupsLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'team.studies.groups',
    options: { onError: { toast: true, slug: 'groups-load' } },
  });

  const requiredMetadata = useMemo<Array<MetadataFieldTypes>>(() => {
    return (
      studyGroupsResponse?.body as {
        data: Array<MetadataFieldTypes>;
      }
    )?.data?.reduce((acc, setting) => (!setting.required ? acc : [...acc, setting?.schema?.field_type]), []);
  }, [studyGroupsResponse]);

  useEffect(() => {
    updateGroupValidation(study.groups, requiredMetadata);
  }, [study, requiredMetadata]);

  if (studyGroupsLoading) {
    return <Loading txt="Loading group settings..." />;
  }
  return (
    <>
      <div className={`ui-card mv4 ${_isEmpty(study.groups) ? 'pa4' : ''}`} style={{ minHeight: 190 }}>
        <StudyGroupSetup
          groups={groups}
          onUpdateGroupData={handleGroupChange}
          addTempId={true}
          conflicts={issues?.size ?? 0}
        />
      </div>
      <div className="ui-card mv4">
        <GroupConfiguration study={study} onChange={handleGroupConfigurationChange} />
      </div>
    </>
  );
};
