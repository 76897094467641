import Loading from '@/components/Loading';
import { mapWithKeys } from '@/helpers';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type { Animal } from '@/model/Animal.model';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';

interface AssignGroupsProps {
  subject: Animal[];
  popMax: number;
  handleCallback: (text?: string) => void;
  closeModal: () => void;
}

interface GroupListProps {
  subjectsToAdd: number;
  groups: TreatmentGroup[] | undefined;
  selectGroup: SelectGroupFunc;
}

interface SelectGroupFunc {
  (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, groupId: ID, name: string): void;
}

const AssignGroups: React.FC<AssignGroupsProps> = ({ subject, popMax, closeModal, handleCallback }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id }: { id: string } = useParams();
  const { collection: groups, collectionLoading: groupsLoading } = useFetchCollection<TreatmentGroup>({
    collectionType: 'studyGroups',
    params: { id },
    includes: 'animals_count',
    queryParams: { perPage: -1 },
  });

  const selectGroup: SelectGroupFunc = (event, groupId) => {
    event.preventDefault();
    setLoading(true);

    let promise;
    if (Array.isArray(subject)) {
      let groupIdMap;
      if (Number.isInteger(groupId)) {
        groupIdMap = mapWithKeys(subject, (instance: Animal) => ({
          [instance.id]: groupId,
        }));
      } else {
        groupIdMap = groupId;
      }

      promise = bulkAssign(subject, groupIdMap);
    } else {
      promise = assign(subject, groupId);
    }

    promise
      .then(() => handleCallback('Successfully assigned group'))
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const assign = (subject: Animal, groupId: ID) => {
    return Http.patch(apiRoute('animal', { id: subject.id }), {
      study_group_id: groupId,
    });
  };

  const bulkAssign = (subjects: Animal[], groupIdMap: Record<string, unknown>) => {
    const data = subjects.map((subject) => {
      return {
        id: subject.id,
        study_group_id: groupIdMap[subject.id],
      };
    });

    return Http.patch(apiRoute('animals.update.bulk'), { animals: data });
  };

  let content;
  if (error) {
    content = (
      <div>
        <div className="pb3 tc">
          <h3 className="normal f4 pb2">Can&#39;t assign these animals to a group.</h3>
          <p className="f6 red">{error}</p>
        </div>
      </div>
    );
  } else {
    const what = Array.isArray(subject) && subject.length > 1 ? 'these animals' : 'this animal';
    const animal = Array.isArray(subject) && subject.length > 1 ? 'animals' : 'animal';

    // TODO this needs to be updated to not use popmax
    // but a number for each
    content = (
      <div>
        <div className="pb3 tc" data-test-element="assign-groups-modal">
          <h3 className="normal f4 pb2">Which group would you like to assign {what} to?</h3>
          <p className="f6 pb2">You can assign up to a maximum of {popMax} animals to each group</p>
          <p className="f6">
            {subject.length} {animal} selected
          </p>
        </div>
        {_notNil(error) && <p className="red">{error}</p>}
        <GroupList
          subjectsToAdd={Array.isArray(subject) ? subject.length : 1}
          groups={groups}
          selectGroup={selectGroup}
        />
      </div>
    );
  }

  return groupsLoading ? (
    <div className="w-100 h-100">
      <Loading />
    </div>
  ) : (
    <form className={`ui__narrow-modal ${loading && 'disabled-form'}`}>
      {content}
      <div className="pt2 bt b--silver">
        <a className="button plain db w-100 mt2" onClick={closeModal}>
          Cancel
        </a>
      </div>
    </form>
  );
};

// TODO test
const GroupList = ({ subjectsToAdd, groups, selectGroup }: GroupListProps) => {
  return (
    <ul className="ui-card-list touch overflow-y-scroll group-list">
      {groups?.map((group) => {
        const pop = group.animals_count ?? 0;
        const capacity = group.max_subjects;
        const hasSpace = pop + subjectsToAdd <= capacity;

        return (
          <li key={group.id}>
            <a className={hasSpace ? '' : 'disabled-link'} onClick={(e) => selectGroup(e, group.id, group.name)}>
              <div className="flex justify-between" data-test-element={`assign-groups-list-${group.id}`}>
                <span>{group.name}</span>
                <span className={!hasSpace ? 'red' : 'mid-gray'}>{`${pop} / ${capacity}`}</span>
              </div>
              {!hasSpace && <span className={'red'}>Not enough room</span>}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default AssignGroups;
