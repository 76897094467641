import { _notNil } from '@/littledash';
import { modalAction } from '@/utils/modal';
import cn from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './TimedDeleteConfirmation.module.scss';

interface TimedDeleteConfirmationProps {
  title: string;
  text?: string;
  render?: () => ReactNode;
  btnTxt: string;
  btnColor?: 'red' | 'dark-blue';
  onClick: () => Promise<void>;
  onCancel?: () => Promise<void> | void;
  onClickClose?: boolean;
}

export const TimedDeleteConfirmation: FC<TimedDeleteConfirmationProps> = ({
  title,
  btnTxt,
  btnColor = 'red',
  text,
  render,
  onClick,
  onCancel,
  onClickClose = true,
}) => {
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const { closeModal } = modalAction(useDispatch());
  const handleClick = async () => {
    if (!deleteDisabled) {
      await onClick();
      if (onClickClose) {
        closeModal();
      }
    }
  };

  return (
    <div className="center mv3 bg-white br2 shadow-4" style={{ maxWidth: '19rem' }}>
      <div className="pa3">
        <div className="pb3 tc">
          <h3 className="normal f4 pb2">{title}</h3>
          {_notNil(render) && _notNil(render()) ? <>{render()}</> : <p className="f6 pb2">{text}</p>}
        </div>
        <div>
          <div
            className={cn(styles.deleteConfirmButton, {
              [styles.red]: btnColor === 'red',
              [styles.darkBlue]: btnColor === 'dark-blue',
            })}
            onAnimationEnd={() => setDeleteDisabled(false)}
            aria-disabled={deleteDisabled}
            onClick={handleClick}
          >
            <div className={styles.content}>{btnTxt}</div>
            <div className={styles.progress}></div>
          </div>
          <a
            className="button plain db w-100 mt2"
            onClick={() => {
              closeModal();
              onCancel?.();
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};
