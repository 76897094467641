import Loading from '@/components/Loading';
import { Dropdown, DropdownMenuDivider, DropdownMenuItem } from '@/components/UI/Dropdown';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { errorToast, successToast } from '@/helpers';
import { _isNil } from '@/littledash';
import type { DataTableApiId, DataTableV1 } from '@/model/DataTable.model';
import type { Study, StudyApiId } from '@/model/Study.model';
import { RiMoreFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { RouteService } from '@/support/RouteService';
import { useModalAction } from '@/utils/modal';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { State } from '@/model/State.model.ts';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList.tsx';
import SelectDropDown from '@/components/UI/SelectDropDown';
import { useMemo } from 'react';

export const DataTablesList = () => {
  const { openModal } = useModalAction();
  const { id: studyId } = useParams<{ id: string }>();

  const { response: studyResponse, loading: studyLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.show.p',
    path: { id: studyId },
    query: { include: 'users' },
    options: { onError: { toast: true, slug: 'study-load' } },
  });

  const study = (studyResponse?.body as { data: Study })?.data;

  const isStudyWriteUser = Auth.isWriteUserForStudy(study as Study);

  const columns: Columns<DataTableV1> = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        sortBy: 'name',
        isVisible: true,
        Cell: ({ row: { original } }) =>
          original && (
            <Link
              to={
                RouteService.web({
                  route: 'studies.dataTables.show',
                  path: { id: studyId, dataTableApiId: original.id },
                }).route
              }
              className="link blue"
            >
              {original.name}
            </Link>
          ),
      },
      {
        id: 'created_at',
        Header: 'Created on',
        accessor: 'created_at',
        sortBy: 'created_at',
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? <DateTimeRenderer value={original.created_at} /> : null),
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: 'type',
        sortBy: 'type',
        isVisible: true,
        sortDisabled: true,
      },
      {
        id: 'animal_count',
        Header: 'Animals',
        accessor: 'animal_count',
        sortBy: 'animal_count',
        isVisible: true,
        sortDisabled: true,
      },
      {
        id: 'actions',
        sortDisabled: true,
        isVisible: isStudyWriteUser,
        width: 35,
        Cell: ({ apiTable: { fetchTableData }, row: { original } }) =>
          original ? (
            <Dropdown
              renderMenu={() => (
                <>
                  <DropdownMenuItem
                    onClick={() =>
                      handleDataTableEdit(original.id as DataTableApiId, fetchTableData as () => Promise<void>)
                    }
                  >
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuDivider />
                  <DropdownMenuItem
                    onClick={() =>
                      handleDataTableDelete(original.id as DataTableApiId, fetchTableData as () => Promise<void>)
                    }
                  >
                    <span className="red">Delete</span>
                  </DropdownMenuItem>
                </>
              )}
            >
              <RiMoreFill size={22} />
            </Dropdown>
          ) : null,
      },
    ],
    [isStudyWriteUser, study]
  );

  const { invoke: invokeDeleteDataTable } = useApiHook({
    endpoint: 'DELETE /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
    path: { studyApiId: study?.api_id as StudyApiId, dataTableApiId: 'dtl_empty' },
    invokeOnInit: false,
  });
  const settings = useSelector((state: State) => state.ui.settings);
  const history = useHistory();
  const navigateToCreateDataTable = () => {
    history.push(
      RouteService.web({
        route: 'studies.dataTables.create',
        path: { id: studyId },
      }).route
    );
  };
  const navigateToCreateDataTableFromTemplate = () => {
    history.push(
      RouteService.web({
        route: 'studies.templates.dataTables.create',
        path: { id: studyId },
      }).route
    );
  };
  const handleDataTableEdit = (dataTableId: DataTableApiId, fetchTableData: () => Promise<void>): void => {
    openModal('EDIT_DATATABLE', {
      studyId: study?.api_id ?? '',
      dataTableId,
      fetchTableData,
    });
  };
  const handleDataTableDelete = (dataTableId: DataTableApiId, fetchTableData: () => Promise<void>): void => {
    openModal('CONFIRM_DELETE_DATATABLE', {
      onClick: async () => {
        try {
          await invokeDeleteDataTable({
            path: { studyApiId: study?.api_id as StudyApiId, dataTableApiId: dataTableId },
          });
          await fetchTableData();
          successToast('Deleted datatable');
        } catch (err) {
          const ivError = new InVivoError('Could not delete data table', {
            slug: 'delete-data-table',
            cause: err,
          });
          ExceptionHandler.captureException(ivError);
          errorToast('Could not delete data table');
        }
      },
    });
  };

  if (studyLoading || _isNil(study)) {
    return <Loading txt="Loading Data tables" />;
  }

  const actions = [
    {
      name: 'Add new',
      action: () => navigateToCreateDataTable(),
    },
    {
      name: 'Create using template',
      action: () => navigateToCreateDataTableFromTemplate(),
    },
  ];

  return (
    <div className="h-100">
      <div className="">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/studies/{studyApiId}/datatables',
              path: { studyApiId: study?.api_id },
            }).url,
          }}
          columns={columns}
          defaultSortBy={{ id: 'created_at', desc: false }}
          searchPlaceholderText="Search"
          settings={settings}
          reduxTableName={'dataTables'}
          testIdPrefix={'dataTables'}
          AsideComponent={() =>
            isStudyWriteUser ? (
              <SelectDropDown
                title="Create new Data table"
                alignMenu="right"
                className="pr4"
                testId="create-new-datatable-select-dropdown"
              >
                <ActionList actions={actions} />
              </SelectDropDown>
            ) : null
          }
        />
      </div>
    </div>
  );
};
