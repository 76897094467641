import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { components } from '@/generated/internal-api/openapi-types';
import { errorToast, successToast } from '@/helpers.tsx';
import { _isNil, _notNil } from '@/littledash.ts';
import InVivoError from '@/model/InVivoError.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { currentUserSelector } from '@/support/Selectors';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { ErrorMessage } from '@hookform/error-message';
import { createSelector } from '@reduxjs/toolkit';
import cn from 'classnames';
import { FC } from 'react';
import { Controller, useForm, Validate } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';
import Banner from '@/components/UI/Banner';

type TeamIntegrationSettingsUpdateV1 = components['schemas']['TeamIntegrationSettingsUpdateV1.schema'];
const urlValidator: Validate<
  TeamIntegrationSettingsUpdateV1['app_installation_url'],
  TeamIntegrationSettingsUpdateV1
> = (value) => {
  try {
    const url = new URL(value);
    if (!url.pathname.startsWith('/apps/app_')) {
      return 'Invalid App URL. It must look like https://<benchling_tenant_domain>/apps/<app_id>';
    }

    return true;
  } catch (err) {
    return 'Invalid App URL';
  }
};

const currentUserStateSelector = createSelector([currentUserSelector], (currentUser) => ({
  currentUser: currentUser,
}));

export const IntegrationConfigV2: FC = () => {
  const { response: detailsResponse, loading: detailsLoading } = useApiHook({
    endpoint: 'GET /api/v1/team/integration-settings',
  });

  if (detailsLoading) {
    return <Loading txt="Loading Integration" />;
  }

  return <IntegrationForm url={detailsResponse?.body?.app_installation_url ?? null} />;
};

const IntegrationForm: FC<{ url: string | null }> = ({ url }) => {
  const { currentUser } = useSelector(currentUserStateSelector);
  const { invoke: updateIntegration } = useApiHook({
    endpoint: 'PATCH /api/v1/team/integration-settings',
    invokeOnInit: false,
    options: { onError: { toast: false, throw: true, capture: false } },
  });
  const formMethods = useForm<TeamIntegrationSettingsUpdateV1>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { app_installation_url: url ?? '' },
  });
  const isUpdate = _notNil(url) || formMethods.formState.isSubmitSuccessful;

  const handleSubmit = async (formData: TeamIntegrationSettingsUpdateV1) => {
    try {
      await updateIntegration({ body: formData });
      successToast(`Integration config ${_isNil(url) ? 'added' : 'updated'}`);
      formMethods.reset(formData);
    } catch (cause) {
      errorToast(`Could not ${_isNil(url) ? 'add' : 'update'} integration config`);
      ExceptionHandler.captureException(
        new InVivoError('Could not save integration details', {
          cause,
          slug: 'integration-details',
          context: { app_installation_url: formData.app_installation_url },
        })
      );
    }
  };
  return (
    <div className="ml3" data-test-component="IntegrationForm" data-test-element="container">
      <div className="flex flex-column">
        <div className="flex flex-row w-100 f3 mt4 near-black">
          <h1 className="f3 normal pb2">
            <span>{_isNil(url) ? 'Add ' : 'Update '}</span> Bioresearch Integration Config
          </h1>
        </div>
        <div className="flex flex-row w-100 mt2 mb4">
          <p className="f5 dark-gray lh-title basier-reg normal pb1">{currentUser?.name ?? ''}</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="ui-card pa4 flex flex-column w-50">
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Controller
              control={formMethods.control}
              name="app_installation_url"
              rules={{ validate: urlValidator }}
              render={({ field, fieldState, formState }) => (
                <div className="mb3" data-test-element="url-container">
                  <label>App Installation URL</label>
                  <input
                    {...field}
                    type="url"
                    className={cn({ ui__error: fieldState.invalid })}
                    data-test-element="url-input"
                  />
                  <ErrorMessage
                    name="app_installation_url"
                    errors={formState.errors}
                    render={({ message }) => (
                      <p className="f6 red db" data-test-element="url-error">
                        {message}
                      </p>
                    )}
                  />
                </div>
              )}
            />
            <Banner info className="mb3 mw6" dismiss={false}>
              <h3 className="f5 normal basier-med lh-title">How to find the App Installation URL in Benchling</h3>
              <p className="f6 pt2 lh-copy">
                Within Connections - Apps, search for the &#34;In Vivo Sync&#34; App. Once you are on the app page, copy
                the URL.
              </p>
            </Banner>
            <div className="mb3" data-test-element="submit-container">
              <Button
                submit
                testId="submit-button"
                disabled={!formMethods.formState.isValid || formMethods.formState.isSubmitting}
                loading={formMethods.formState.isSubmitting}
                loadingText={isUpdate ? 'Updating' : 'Adding'}
              >
                {isUpdate ? 'Update' : 'Add'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
