// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';
import _differenceBy from 'lodash/differenceBy';
import { CageCardCollection } from '@/components/Cages/Show/Cages.tsx';

/**
 * Used to pull all of the animals into a flat array
 * by their ID out of the `study.collections` prop
 */
export const flatMapAnimalsFromCollectionsById = (collections, selection) => {
  const selectedAnimals = [];

  if (!_isEmpty(collections) && Array.isArray(collections) && !_isEmpty(selection) && Array.isArray(selection)) {
    collections.map((c) => {
      if (!_isEmpty(c.subjects.data)) {
        c.subjects.data.map((s) => {
          if (selection.includes(s.id)) {
            selectedAnimals.push(s);
          }
        });
      }
    });

    return selectedAnimals;
  }

  return selectedAnimals;
};

/**
 * Compares two arrays of objects, returns the new addition
 */

export const returnNewObjectsAddedToArray = (arr1, arr2, key) => {
  if (!_isEmpty(arr1) && Array.isArray(arr1) && !_isEmpty(arr2) && Array.isArray(arr2)) {
    return _differenceBy(arr2, arr1, key);
  }

  return [];
};

const countCageNameDuplicates = (cages: Array<CageCardCollection>) =>
  cages.reduce((acc: Record<string, number>, cage) => {
    acc[cage.name] = (acc[cage.name] || 0) + 1;
    return acc;
  }, {});

// Returns a new array of cages with a boolean flag indicating if the cage name is a duplicate amongst the current collection
export const findDuplicateNames = (cages: Array<CageCardCollection>) => {
  const nameCount = countCageNameDuplicates(cages);
  return cages.map((cage) => ({
    ...cage,
    duplicateCageName: nameCount[cage.name] > 1,
  }));
};

// Returns the number of cages with duplicate names, excluding the first instance of each name
export const countUniqueDuplicates = (cages: Array<CageCardCollection>) => {
  const nameCounts = countCageNameDuplicates(cages);
  return Object.values(nameCounts).reduce((acc, count) => {
    if (count > 1) {
      acc += count - 1;
    }
    return acc;
  }, 0);
};
