import { _notNil, uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';
import { MetadataFieldTypes } from '@/model/Metadata.model.ts';
import { TreatmentGroup } from '@/model/TreatmentGroup.model.ts';

interface TempID {
  temp_id?: ID;
}

export const tempEntityArrayToMap = <T extends TempID>(entities: Array<T>): Map<ID, T> =>
  new Map(
    (entities ?? []).map((entity) => {
      const tempId = entity?.temp_id ?? uuid();
      return [tempId, { ...entity, temp_id: tempId }];
    })
  );
export type InvalidMetadataFields = { type: 'invalid-metadata'; fields: Array<MetadataFieldTypes> };

export const validateGroups = (
  groups: Array<TreatmentGroup>,
  metadataFields: Array<MetadataFieldTypes>
): Map<string, InvalidMetadataFields> =>
  groups.reduce((acc, group) => {
    const invalidMetadataIssue = validateGroupMetadata(group, metadataFields);
    if (_notNil(invalidMetadataIssue)) {
      acc.set(group?.temp_id ?? '', invalidMetadataIssue);
    }
    return acc;
  }, new Map<string, InvalidMetadataFields>());

const validateGroupMetadata = (
  group: TreatmentGroup,
  metadataFields: Array<MetadataFieldTypes>
): InvalidMetadataFields | undefined => {
  const invalidMetadataFields = (metadataFields ?? []).reduce<Array<MetadataFieldTypes>>(
    (acc, metadataField: MetadataFieldTypes) =>
      (group?.metadata?.map(({ field_type }) => field_type) ?? []).includes(metadataField)
        ? acc
        : [...acc, metadataField],
    []
  );
  if (invalidMetadataFields.length > 0) {
    return { type: 'invalid-metadata', fields: invalidMetadataFields };
  }
};
